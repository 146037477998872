<template>
    <div id="about">
        <div class="container-fluid-2x">
            <div class="row d-flex justify-content-center align-items-center">
                <div class="col-sm-12 col-md-6">
                    <h1 class="about-heading">O serwerze</h1>
                    <p class="about-description">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Repudiandae assumenda possimus dolorem sint eius nihil aperiam neque consectetur blanditiis excepturi aliquam laboriosam fuga, sapiente atque animi ratione, optio iusto minima. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Odit, quos dolorum. Cumque deleniti tenetur itaque dolorum enim cupiditate pariatur optio a nulla, culpa eaque sequi, sed sit dolorem quasi fugiat.</p>
                    <div class="about-button-wrapper d-flex justify-content-center">
                        <a href="#" class="btn btn-lg btn-primary">Statystyki</a>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 d-flex justify-content-end">
                    <img class="about-image" src="../../assets/about-render.png" alt="player render">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AboutServer'
    }
</script>

<style scoped>

</style>