<template>
    <div id="footer">
        <p class="footer-text">Created by Wojciech Czapkowicz</p>
    </div>
</template>

<script>
export default {
    name: 'PageFooter'
}
</script>

<style lang="scss" scoped>

</style>