<template>
    <div id="map">
        <div class="container-fluid">
            <h1>Mapa serwera</h1>
            <iframe id="map_iframe" src="https://craftserve.pl/s/603820/map?widget&amp;proxy#/-213/64/181/-2/world/Mapa%203D" style="height: 100%;width: 100%;border: 0; min-height: 500px;display: block;height: 500px;"></iframe>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GameMap'
}
</script>

<style lang="scss" scoped>

</style>