<template>
  <div class="container-fluid">
    <div class="heading-wrapper">
      <div class="heading">
        <img src="../../assets/logo.png" alt="server logo" class="heading-logo">
        <h1 class="heading-title">NazwaSerwera.pl | Twój serwer Skyblock</h1>
        <div class="heading-server-status-wrapper">
          <span class="pulse-animation pulse-dot-20"></span>
          <p class="heading-server-status">Status serwera: Online [5/100]</p>
        </div>
        <a href="#" class="btn btn-lg btn-outline-primary heading-button">Zagraj teraz!</a>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'PageHeader',
  }
</script>

<style scoped>

</style>
