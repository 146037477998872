<template>
    <div id="features">
        <div class="container-fluid">
            <div class="row d-flex justify-content-center">
                <div class="col-sm-12 col-md-6 col-lg-4 feature">
                    <div class="feature-icon">
                        <h3><i class="fas fa-star"></i></h3>
                    </div>
                    <h3 class="feature-heading">Lorem ipsum</h3>
                    <p class="feature-description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem
                        provident maxime quisquam laboriosam</p>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-4 feature">
                    <div class="feature-icon">
                        <h3><i class="fas fa-user"></i></h3>
                    </div>
                    <h3 class="feature-heading">Lorem ipsum</h3>
                    <p class="feature-description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem
                        provident maxime quisquam laboriosam</p>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-4 feature">
                    <div class="feature-icon">
                        <h3><i class="fas fa-wallet"></i></h3>
                    </div>
                    <h3 class="feature-heading">Lorem ipsum</h3>
                    <p class="feature-description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem
                        provident maxime quisquam laboriosam</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'FeaturesSection',
  }
</script>

<style lang="scss" scoped>

</style>