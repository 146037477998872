<template>
  <main>
      <router-view></router-view>
  </main>
</template>

<script>

export default {
  name: 'ItemShop',
}
</script>

<style lang="scss">
  @import '../public/css/main.scss';
</style>
