<template>
    <div id="cta">
        <div class="container-fluid">
            <h1>Dołącz do 10 graczy online!</h1>
            <a href="#" class="btn btn-lg btn-outline-primary heading-button">Zagraj teraz!</a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LandingCta',
}
</script>

<style lang="scss" scoped>

</style>